<template>
  <div>
    <span class="ml-2 text-warning" v-if="hasProductsByWeight()">
      <font-awesome-icon icon="balance-scale" />
      {{ $t("role.orders.manage.ddmp.detail.delivery.table.priceNotWeighed") }}
    </span>
    <strong v-else>{{ getPrice() }}</strong>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../../../helpers/common";

export default {
  name: "ProductsTablePrice",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      return formatPrice(this.data.price_p, this.currencySymbol).format();
    },

    hasProductsByWeight() {
      return get(this.data, "need_w", 0) > 0;
    },
  },
};
</script>
